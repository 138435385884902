<template>
  <div class="news-page purple-bg">
    <Header class="purple-bg" />
    <section class="homepage purple-bg centering">
      <div class="home-title">
        <h1>LE ULTIME<span>NEWS</span></h1>
      </div>
    </section>

    <!-- <<<< GALLERY >>>> -->
    <section class="container-fluid p-0" id="openmodal">
      <!-- <<<< MODAL >>>> -->
      <div
        class="singolo-evento-opened-modal"
        v-if="isSingoloEventoOpen"
        ref="eventModal"
      >
        <div class="news-modal-content mx-50">
          <div class="modal-title">
            <h1>{{ selectedEvent.titolo }}</h1>
            <img
              @click="closeSingoloEvento"
              src="../assets/img/close-btn.svg"
              alt="close"
              draggable="false"
            />
          </div>

          <div class="d-flex flex-column modal-centering">
            <img
              class="foto-open"
              :src="`https://siciliapuntol.it/contents/${selectedEvent.photo}`"
              alt="foto evento"
              draggable="false"
            />
            <p class="desc-open" v-html="selectedEvent.descrizione"></p>
            <a
              :href="selectedEvent.link"
              target="_blank"
              v-if="selectedEvent.link"
            >
              <button class="button-border">
                SCOPRI DI PIÙ
                <svg
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 1L5 5L1 9" stroke="black" />
                </svg>
              </button>
            </a>
          </div>
        </div>

        <section class="random-news mx-50 d-flex orange-bg">
          <h1 class="section-title">
            LE ULTIME<br />
            NEWS
          </h1>

          <div class="random-event-cnt d-flex">
            <div
              v-for="(latestEvent, eventIndex) in latestEvents"
              :key="eventIndex"
              class="random-event"
            >
              <img
                :src="`https://siciliapuntol.it/contents/${latestEvent.photo}`"
                draggable="false"
              />

              <h2>{{ latestEvent.titolo }}</h2>
              <button
                class="button-border"
                @click="openSingoloEventoFromModal(latestEvent)"
              >
                LEGGI DI PIÙ
                <svg
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 1L5 5L1 9" stroke="#FFFFEC" />
                </svg>
              </button>
            </div>
          </div>
        </section>
      </div>

      <div v-if="!isSingoloEventoOpen" class="row mx-50 news-scroll purple-bg">
        <div
          class="evento-news"
          v-for="(evento, index) in eventiRecenti"
          :key="index"
        >
          <div class="col-4 photo-cnt">
            <img
              :src="`https://siciliapuntol.it/contents/${evento.photo}`"
              alt="foto news"
              draggable="false"
            />
          </div>
          <div class="col-8 infos">
            <p class="news-subtitle" v-if="selectedEvent.sottotitolo">
              {{ evento.sottotitolo }}
            </p>
            <h2 class="news-title">
              {{ evento.titolo }}
            </h2>

            <button class="button-border" @click="openSingoloEvento(evento)">
              VAI
              <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 1L5 5L1 9" stroke="#FFFFEC" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- <<<< FOOTER >>>> -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "LeNostreNews",
  components: {
    Header,
    Footer,
  },

  data() {
    return {
      eventiRecenti: [],
      selectedEvent: false,
      isSingoloEventoOpen: false,
    };
  },

  async mounted() {
    let eventi_data = await fetch(
      "https://www.siciliapuntol.it/api/eventi-recenti"
    );
    let eventiRecenti = await eventi_data.json();
    this.eventiRecenti = eventiRecenti;
    if (this.$route.query.id) {
      const eventToOpen = this.eventiRecenti.find(
        (el) => el.id.toString() === this.$route.query.id
      );
      if (eventToOpen) {
        this.openSingoloEvento(eventToOpen);
      }
    }
  },

  methods: {
    openSingoloEvento(selectedEvent) {
      this.isSingoloEventoOpen = true;
      this.selectedEvent = selectedEvent;
    },
    openSingoloEventoFromModal(selectedEvent) {
      this.selectedEvent = selectedEvent;
      console.log(this.$refs.eventModal);
      this.$refs.eventModal.scrollTo(0, 0);
    },

    closeSingoloEvento() {
      this.isSingoloEventoOpen = false;
      this.selectedEvent = false;
    },
  },

  computed: {
    latestEvents() {
      return this.eventiRecenti
        .filter((el) => el.id != this.selectedEvent.id)
        .slice(0, 2);
    },
  },
};
</script>